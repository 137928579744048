import { RegisterOptions } from 'react-hook-form'

// バリデーション定義の合成用ヘルパ
// 次のように使用してください:
// <Input
//   type="text"
//   {...register('name', {
//     ...compose(
//        validateRequired('ラベル'),
//        validateFoo('ラベル'),
//        validateBar('ラベル'),
//     )
//   })}
// />
//
export const compose = (...optionsList: RegisterOptions[]) => {
  let mergedOptions: RegisterOptions = {}

  for (const options of optionsList) {
    mergedOptions = {
      ...mergedOptions,
      ...options,
      validate: {
        ...mergedOptions.validate,
        ...options.validate,
      },
    }
  }

  return mergedOptions
}

// バリデーション定義は、react-hook-form の register に渡せる形式で記載する。

// 定義済みメッセージ一覧:
// https://micin.atlassian.net/wiki/spaces/engdesign/pages/212239636/90.+-

export const validateRequired = (label: string) => {
  return {
    required: `${label}を入力してください。`,
  }
}

export const validatePasswordPattern = (label?: string) => {
  return {
    validate: {
      passwordPattern: (v: any) =>
        (typeof v === 'string' &&
          /^(?=.*[A-Za-z!@#$&*_])(?=.*[0-9])[A-Za-z0-9!@#$&*_]{8,256}$/.test(v)) ||
        `パスワードは数字、英字を1文字以上含む、8文字以上の半角英数字記号で入力してください。`,
    },
  }
}

// ID-E-0003
export const validateEmailPattern = (label?: string) => {
  return {
    validate: {
      emailPattern: (v: any) => {
        if (typeof v === 'string' && !/^[a-zA-Z0-9!?@#$&+\-*_.,]+$/.test(v)) {
          return `${label}は半角英数字記号で入力してください。`
        }

        return (
          (typeof v === 'string' && /^[^@\s]+@([^@\s]+\.)+[^@\s]+$/.test(v)) ||
          `入力したメールアドレスが間違っています。正しいメールアドレスを入力してください。`
        )
      },
    },
  }
}

// ID-E-0005
export const validatePinCodePattern = (label?: string) => {
  return {
    validate: {
      pinCodePattern: (v: any) => {
        if (typeof v !== 'string' || v.length !== 6) {
          return '認証コードを入力してください。'
        }
        if (!/^\d*$/.test(v)) {
          return `認証コードは数字で入力して下さい。`
        }

        return true
      },
    },
  }
}

// ID-E-0015(半角数字)
export const validateNumberCharacters = (label: string) => {
  return {
    validate: {
      numberCharacters: (v: any) =>
        (typeof v === 'string' && /^\d*$/.test(v)) || `${label}は半角数字で入力してください。`,
    },
  }
}

// ID-E-0033
export const validateDigitString = (label: string, digit: number) => {
  return {
    validate: {
      digitString: (v: any) =>
        (typeof v === 'string' && /^\d*$/.test(v) && v.length === digit) ||
        `${label}の桁数が異なります。`,
    },
  }
}

// ID-E-0032
// NOTE: 先頭が070,080,090があるかどうかの簡易的なチェック（実在しうる番号かまではチェックしていない）
export const validateMobileNumber = () => {
  return {
    validate: {
      mobileNumber: (v: any) =>
        (typeof v === 'string' && /^(070|080|090)\d{8}$/.test(v)) || `無効な携帯電話番号です。`,
    },
  }
}

// ID-E-0031
export const validateMobileNumberChange = (existingNumber: string) => {
  return {
    validate: {
      mobileNumberChange: (v: any) =>
        (typeof v === 'string' && v !== existingNumber) ||
        `ご指定頂いた携帯電話番号は既に使用されています。`,
    },
  }
}

// ID-E-0022
export const validateDigit = (label: string, digit: number) => {
  return {
    validate: {
      digit: (v: any) =>
        (typeof v === 'string' && /^\d*$/.test(v) && v.length === digit) ||
        `${label}は${digit}桁の数値で入力して下さい。`,
    },
  }
}

// ID-E-0023
export const validateDigitRange = (label: string, min: number, max: number) => {
  return {
    validate: {
      digitRange: (v: any) =>
        (typeof v === 'string' && /^\d*$/.test(v) && v.length >= min && v.length <= max) ||
        `${label}は${min}桁～${max}桁の数値で入力して下さい。`,
    },
  }
}

// ID-E-0018
export const validateMonth = (label: string) => {
  return {
    validate: {
      month: (v: any) =>
        (typeof v === 'string' && /^(0[1-9]|1[0-2])$/.test(v)) ||
        `${label}は01月から12月までの値を入力してください。`,
    },
  }
}

export const validateMaxLength = (label: string, value: number) => {
  return {
    maxLength: { value: value, message: `${label}は${value}文字以内で入力して下さい。` },
  }
}
